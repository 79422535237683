import Script from 'next/script';

import {
  selectConfig,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

const PayUScript = () => {
  const {
    modules: {
      PayU: { sandbox = true },
    },
  } = useAppConfigSelector(selectConfig);

  if (sandbox) {
    return (
      <Script
        id="payu-sandbox-script"
        type="text/javascript"
        src="https://secure.snd.payu.com/javascript/sdk"
      />
    );
  }

  return (
    <Script
      id="payu-script"
      type="text/javascript"
      src="https://secure.payu.com/javascript/sdk"
    />
  );
};

export default PayUScript;
